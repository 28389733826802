<template>
  <div>
    <br />
    <div class="row">
      <div class="col-3 my-3">
        <label for=""> Employee </label>
        <select
          class="form-select"
          v-model="transaction.employee_reference"
        >
          <option value="" default></option>
          <option
            v-for="(employee, index) in employees"
            :key="index++"
            :value="employee.reference"
          >
            <div class="row justify-content-between">
              <span class="col-auto">
                {{ employee.fullName }}
              </span>
              <span class="col-auto">
                {{ employee.society }}
              </span>
            </div>
          </option>
        </select>
      </div>

      <div class="col-3 my-3">
        <label for="">Mode de paiement</label>
        <select v-model="transaction.transactionMethod" class="form-select">
          <option value="Espèce">Espèce</option>
          <option value="Virement bancaire">Virement bancaire</option>
          <option value="Chèque">Chèque</option>
          <option value="Effet">Effet</option>
        </select>
      </div>

      <div class="col-3 my-3">
        <label for="">Montant</label>
        <input type="text" v-model="transaction.amount" class="form-control" />
      </div>
    
      <div class="col-3 my-3">
        <label for="">Date</label>
        <input type="date" v-model="transaction.date" class="form-control" />
      </div>
      

      <div class="col-3 my-3">
        <label for="">Numéro de chèque</label>
        <input type="text" v-model="transaction.checkNumber" class="form-control" />
      </div>

      <div class="col-3 my-3">
        <label for=""> Destination </label>
        <select v-model="transaction.destination" class="form-select">
          <option value="Paiement d'un salaire"> Paiement d'un salaire </option>
          <option value="Paiement d'une facture fournisseur "> Paiement d'une facture fournisseur  </option>
          <option value="Virement sur le compte bancaire"> Virement sur le compte bancaire </option>
        </select>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-12">
        <label for="">Remarques</label>
        <textarea class="form-control" cols="30" rows="3"></textarea>
      </div>
    </div>
    <br />
    <div class="row mt-3 justify-content-between">
      <div class="col-auto"></div>
      <div class="col-auto">
        <button class="btn btn-success" @click="save(transaction)">
          <i class="bi bi-check-square-fill"></i> Enregistrer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      transaction: {
        date: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],
      },
    };
  },
  computed: {
    ...mapGetters("employee", {
      employees: "getAll",
    }),
  },
  methods: {
    async save(data) {
      await this.$store.dispatch("transaction/store", data);
    },
    
  },
  beforeMount() {
    this.$store.dispatch("employee/getAll");
  },
};
</script>
